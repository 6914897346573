<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="Add New" 
                :showBtn="false"
                :showAddNew="false"
                title="Sales Invoice From Order"
            />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
            >
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-tpp-1">
                    <label class="form-label">Select BDO</label>
                    <AsyncSelect
                        placeholder="Select BDO"
                        v-model="singleSR"
                        :api-service="fetchAllSR"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-tpp-1">
                    <label for="colFormLabel" class="form-label">Bank-Branch</label>
                    <AsyncSelect
                        placeholder="Select Bank-Branch"
                        v-model="singleDealer"
                        :api-service="fetchAllDealers"
                        :additional-query="additionalDealerQuery"
                        :additional-option="additionalDealerOption"
                        :key="dealerRenderKey"
                        :disabled="! Boolean(singleSR)"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">

                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Party: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>

                    <AsyncSelect
                        placeholder="Select Party"
                        v-model="contactProfile"
                        :api-service="fetchContactProfiles"
                        :additional-query="additionalContactOption"
                        :key="contactRenderKey"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-1">
                    <label class="form-label">Status</label>
                    <v-select
                        placeholder="Select Status"
                        v-model="selectedOrderStatus"
                        :options="orderStatuses"
                        label="name"
                        :reduce="option => option.value"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-1">
                    <label class="form-label">Invoice Status</label>
                    <v-select
                        placeholder="Select Invoice Status"
                        v-model="selectedInvoiceStatus"
                        :options="invoiceStatuses"
                        label="name"
                        :reduce="option => option.value"
                    />
                </div>
            </DateQuerySetter>

        </div>
        <div class="col-12 px-2 mt-2">
            <SalesOrderListTable :sales-orders="purchaseList" />
        </div>
        <div class="px-2"><Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/></div>
        <ContactDropdownFilter 
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            type="customer"
        />
    </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import { ref, inject, onMounted, computed, watch  } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/inventory/sales/ListTable.vue'
import handleContact from "@/services/modules/contact";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleCrm from '@/services/modules/crm/crm'
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import SalesOrderListTable from "@/components/molecule/company/inventory/sale-order-invoice/ListTable.vue";
import handleRole from "@/services/modules/role";

const router = useRouter()
const store = useStore()
let offset = ref(20)
let purchaseList = ref([])
let contactProfile = ref(null)
const route = useRoute()
const dealerRenderKey = ref(0);
const contactRenderKey = ref(0);

const {fetchSalesOrderList, fetchOrderStatus} = handlePurchase()
const {fetchContactProfiles} = handleContact();
const { fetchAllDealers, fetchAllSR } = handleCrm();
const { fetchCompanyDefaultRoles } = handleRole();
const additionalDealerQuery = ref({});
const singleSR = ref(null);
const singleDealer = ref(null);
const additionalContactOption = ref({
    with_mobile: 1,
    dealer_id: '',
    roles: 'customer'
});
const additionalDealerOption = ref([]);
const invoiceStatuses = ref([]);
const companyRoles = ref([]);
const orderStatuses = ref([]);
const selectedInvoiceStatus = ref(null);
const selectedOrderStatus = ref(null);


const companyId = computed(() => {
    return route.params.companyId
})
const startDate = computed(() => {
    return route.query.start
})
const endDate = computed(() => {
    return route.query.end
})
const page = computed(() => {
    return route.query.page
})

watch(() => singleSR.value, (newValue, oldValue) => {
    additionalDealerQuery.value = {};
    additionalContactOption.value.dealer_id = '';
    singleDealer.value = null;
    contactProfile.value = null;
    dealerRenderKey.value += 1;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalDealerQuery.value = {'sale_representative_id': newValue.id}
    }
})

watch(() => singleDealer.value, (newValue, oldValue) => {
    additionalContactOption.value.dealer_id = '';
    contactProfile.value = null;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalContactOption.value.dealer_id = newValue.id
    }
})

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
    contactProfile.value = contact
}

function navigateToAddPage() {
    router.push({name: 'sales-invoice-add', params: route.params, query:route.query})
}

function getQuery () {
    let query = '?company_id=' + companyId.value
    if(!startDate.value && !endDate.value) return query
    if(contactProfile.value) query += '&contact_profile_id='+contactProfile.value.id
    if(startDate.value) query += '&start_date='+startDate.value
    if(endDate.value) query += '&end_date='+endDate.value
    if(page.value) query += '&page='+page.value
    if(selectedOrderStatus.value) query += '&status='+selectedOrderStatus.value
    if(selectedInvoiceStatus.value) query += '&invoice_status='+selectedInvoiceStatus.value
    query += '&offset='+offset.value
    return query
}

const getOrderStatus = async () => {
    const res = await fetchOrderStatus('?company_id=' + companyId.value);
    if (res.status) {
        invoiceStatuses.value = res.data.filter(item => ['created', 'pending'].includes(item.value))
        orderStatuses.value = res.data.filter(item => ['pending', 'approved'].includes(item.value))
    }
}

const getCompanyRoles = async () => {
    const res = await fetchCompanyDefaultRoles(`?company_id=${companyId.value}&roles=customer,lead`);
    if (res.status) {
        companyRoles.value = res.data
    }
}

function onClickGo() {
    fetchSalesOrderList(getQuery()).then(res => {
        if (!res.status) {
            purchaseList.value = []
            store.commit("resetPagination")
            return;
        }

        purchaseList.value = res.date.data
        setPagination(res.date)
    })
}

function setPagination(data) {
    if(!data.data.length) return
    store.commit('setPaginationData', {
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    router.push({path: route.path, query: routeQuery})
    onClickGo()
}

onMounted(() => {
    onClickGo()
    getOrderStatus()
    getCompanyRoles()
})

</script>

<style scoped>
.mt-tpp-1 {
    margin-top: 1%;
}
.filter-label {
    padding-right: 0.5rem !important;
}
</style>