<template>
    <div class="card" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Order Number</th>
                    <th>Invoice Number</th>
                    <th class="text-right">Order Amount</th>
                    <th>Party Name</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in salesOrders" :key="i">
                    <td> {{ item.date }}</td>
                    <td> {{ item.bill_number }}</td>
                    <td> {{ item.sale_master_bill_number ?? 'N/A' }}</td>
                    <td class="text-right"> {{ item.bill_amount }}</td>
                    <td> {{ item.party_name }}</td>
                    <td class="d-flex flex-wrap gap-1">
                        <button
                            v-if="!item.sale_master_id"
                            class="btn btn-primary btn-sm"
                            @click="goToCreateInvoice(item.id)"
                            style="cursor: pointer"
                        >
                          <i class="fas fa-file-alt"></i>
                        </button>
                        <slot v-if="item.sale_master_id">
                            <button
                                class="btn btn-secondary btn-sm"
                                @click="goToPrint(item.sale_master_id, 1)"
                                style="cursor: pointer"
                            >
                                <i class="fas fa-print"></i>
                            </button>

                            <button
                                class="btn btn-secondary btn-sm btn-challan"
                                @click="goToPrint(item.sale_master_id, 2)"
                                style="cursor: pointer;"
                            >
                                <i class="fas fa-print"></i>
                            </button>
                        </slot>
                    </td>
                </tr>

                </tbody>
            </table>
            <p v-if="!salesOrders.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<style scoped>
.btn-challan {
    background-color: #249024 !important;
}
</style> 

<script>
import figureFormatter from '@/services/utils/figureFormatter';
import handlePurchase from "@/services/modules/purchase";
import pdfPrinter from '@/services/utils/pdfPrinter';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import handleHospital from '@/services/modules/hospital'
import useDate from "@/services/utils/day";

export default {  
    name: 'SalesOrderListTable',
    data() {
        return{
            companyInfo: {},
            invoice: {},
            barcodeQuery: '',
            barcode: '',
            user: {},
            qrcode: '',
            invoiceNotes: []
        }
    },
    props: {
        salesOrders: Array
    },
    setup() {
        const {commaFormat} = figureFormatter()
        const { fetchSalesBill } = handlePurchase();
        const {fetchCompanyInfo} = handleCompany();
        const { exportToPDF } = pdfPrinter();
        const { fetchBarcode } = handleBarcode();
        const store = useStore();
        const { notesByType } = handleHospital()
        const { formatDate } = useDate();

        return {
            commaFormat,
            fetchSalesBill,
            exportToPDF,
            fetchCompanyInfo,
            fetchBarcode,
            formatDate,
            store,
            notesByType
        }
    },
    mounted() {
         this.fetchCompanyInfo(this.$route.params.companyId).then( (res) => {
            this.companyInfo = res.data;
         }).catch((error) => {
            console.log(error);
         });

         this.getInvoiceNotes();

         this.user = this.store.state.user.user
    },
    methods: {
        async getInvoiceNotes () {
            const companyQuery = `?company_id=${this.$route.params.companyId}&show_default_only=1`;
            await this.notesByType(companyQuery, "invoice_terms").then((res) => {
                if(! res.status) return;
                this.invoiceNotes = res.data.filter((item) => item.full_desc);
            })
        },
        goToCreateInvoice(id) {
            this.$router.push({
                name: 'sales-invoice-add',
                params: this.$route.params,
                query: {
                    start: this.$route.query.start,
                    end: this.$route.query.end,
                    sales_order_id: id,
                    redirect_route: 'sales-order-invoice'
                }
            })
        },
        goToPrint(id, btnFunc) {
            const companyQuery = `?company_id=${this.$route.params.companyId}`;
            this.fetchSalesBill(id, companyQuery).then( (res) => {
                if( res ) {
                    this.invoice = res.data;
                    let qrcodeData = JSON.stringify({
                        ref_no: this.invoice.bill_number,
                        ref_date: this.invoice.date,
                        created_by: this.invoice.created_by_name,
                        created_date_time: this.formatDate(new Date())
                    })
                    this.barcodeQuery = `?code=${this.invoice.bill_number}&qrcode=${qrcodeData}`;     
                }
            }).then (() => {
                this.fetchBarcode(this.barcodeQuery).then( (res) => {
                    this.barcode = res.barcode;
                    this.qrcode = res.qrcode
                }).then( () => {
                    this.exportToPDF(
                        this.companyInfo,
                        this.invoice,
                        this.barcode,
                        this.qrcode,
                        this.user.name,
                        btnFunc,
                        this.invoiceNotes
                    )
                })
            }).catch( () => {
                console.log("Something went wrong");
            })
        }
    }
}
</script>
